<template>
  <div>
    <div class="d-flex justify-content-between flex-wrap mb-2 px-2">
      <!-- sorting  -->
      <b-form-group
        class="mr-1 mb-md-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="sortBy"
            :options="sortOptions"
          >
            <template #first>
              <option value="">
                none
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="sortDesc"
            size="sm"
            :disabled="!sortBy"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>

      </b-form-group>

      <!-- filter -->
      <b-form-group>
                
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>

      </b-form-group>

    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <!-- Payment Status -->
      <template #cell(payment_status)="row">
        <b-badge
          v-if="row.item.payment_status === 'Paid'"
          variant="success"
        >
          {{ row.item.payment_status }}
        </b-badge>
        <b-badge
          v-if="row.item.payment_status !== 'Paid'"
          variant="danger"
        >
          {{ row.item.payment_status }}
        </b-badge>
      </template>

      <template #cell(action)="row">
        <b-button
          variant="outline-primary"
          @click="view(row.item.id)"
        >
          View
        </b-button>
      </template>

      <!-- Work Type -->
      <template #cell(work_type)="row">
        {{ row.item.work_type ? row.item.work_type : 'CONTRACT' }}
      </template>

      <!-- Invoice Total -->
      <template #cell(invoice_total)="row">
        {{ '$' + row.item.invoice_total }}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BTable, BButton, BCardBody, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BButton,
    BCardBody,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'invoice_number', label: 'Invoice Number' },
        { key: 'invoice_date', label: 'Invoice Date', sortable: true },
        { key: 'payment_date', label: 'Payment Date', sortable: true },
        { key: 'payment_status', label: 'Payment Status' },
        { key: 'work_period', label: 'Work Period' },
        { key: 'work_type', label: 'Work Type' },
        { key: 'invoice_total', label: 'Invoice Total', sortable: true },
        { key: 'action', label: 'Action' },
      ],
      perPage: 30,
      pageOptions: [30, 40, 50],
      totalRows: 1,
      currentPage: 1,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    // detail view of payment
    view(id) {
      this.$router.push(`payment/detail/${id}`)
    },
  },
}
</script>
